<div class="editorial-astrea">

    <mat-drawer-container class="astrea-container">

        <section class="astrea-header">
            <mat-toolbar>
                <astrea-menu-navbar
                    (handleMenu)="handleMenu()"
                ></astrea-menu-navbar>
            </mat-toolbar>
        </section>

        <section class="astrea-content">
            <router-outlet></router-outlet>
            <!-- <div class="return-old-av">
                <a class="d-flex j-c-center a-i-center" href="https://www.astreavirtual.com.ar" target="_blank" title="{{ 'previous_version' | translate }}">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    {{ 'previous_version' | translate }}
                </a>
            </div> -->
        </section>

        <section>
            <astrea-footer></astrea-footer>
        </section>


        <!-- Menu Mobile -->
        <mat-drawer #sidenav [mode]="'over'">
            <astrea-menu-leftnav
                (handleMenu)="handleMenu()"
            ></astrea-menu-leftnav>
        </mat-drawer>


    </mat-drawer-container>

    <div *ngIf="devMode != 'production'" class="development-mode">
        <p>Development</p>
    </div>

</div>
