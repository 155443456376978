import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(
    private http: HttpClient,
  ) { }


  getUser(): Observable<any> {
    return this.http.get(`${environment.URL_API_AV}/api/user/me`);
  }

  putUser(data: any): Observable<any> {
    return this.http.put(`${environment.URL_API_AV}/api/user/me/update`, data);
  }

}
